import React from "react";

import { Container, Wrapper } from "../../../components/util";
import {
  IntegrationsPageHeader,
  WhatIsIntegration,
  WhatIsInvoxy,
  IntegrationsNav,
} from "../../../components/site";
import Image from "../../../components/image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import JobAdderLogo from "../../../images/logos/Other/JobAdder_Blue_Full.svg";

const JobAdder = (props) => (
  <Layout>
    <Seo
      title="JobAdder Integration | Karmly"
      description="Add or edit clients, resources & placements in JobAdder and it will seamlessly update in Karmly for timesheets & invoicing."
      pathname={props.location.pathname}
    />
    <IntegrationsNav />
    <IntegrationsPageHeader
      category="RECRUITMENT MANAGEMENT"
      title="Connect Karmly with JobAdder"
      description="Automatically send new placements to Karmly."
    />
    <Container>
      <Wrapper>
        <h4 className="-textCenter">
          Add or edit clients, resources & placements in JobAdder and it will
          seamlessly update in Karmly for timesheets & invoicing.
        </h4>
        <Image
          filename="JobAdder_Feature.png"
          alt="JobAdder + Karmly Integration"
        />
      </Wrapper>
    </Container>
    <WhatIsInvoxy />
    <WhatIsIntegration
      title="What is JobAdder?"
      description="JobAdder is a global recruitment platform built for staffing agency and in-house corporate recruiters. Used by over 10,000 people every day, we empower recruiters all over the world to recruit smarter and simpler."
      integration="JobAdder"
      link="https://jobadder.com/demo"
      logo={JobAdderLogo}
      details={[
        "Streamline all jobs, candidates and tasks through one centralised dashboard.",
        "Post job ads to over 200 job boards and social platforms simultaneously.",
        "Communicate with candidates, clients and co-workers using JobAdder’s diverse CRM functionality, including SMS, @mentions and customisable templates.",
        "Get all the help you need from our friendly Customer Support Pirates who are on hand 24/6 to assist you with any enquiries.",
        "Tap into passive candidate markets on social media using PeopleAdder, JobAdder’s bespoke social talent acquisition feature.",
        "Connect with some of the world’s leading software providers through our Integrations Marketplace to automate every end of your recruitment workflow.",
        "Enjoy total flexibility to recruit everywhere from any mobile device.",
      ]}
    />
  </Layout>
);

export default JobAdder;
